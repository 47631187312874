import { Button } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { useDefineUserRole } from '../../../hooks';

interface IProps {
  onPress?: ()=> void
}

const ButtonUpload: React.FC<IProps> = ({onPress}) => {
  const { me } = useSelector(({ profile }: any) => profile);
  const isRoleVendor = useDefineUserRole(me, 'ROLE_VENDOR');
  const isRoleAdmin = useDefineUserRole(me, 'ROLE_ADMIN');

  return (
    <>
      {(isRoleAdmin || isRoleVendor) && (
        <Button  color='default' variant='outlined' 
        style={{ marginLeft: '10px' }} onClick={onPress}
        >
          ИМПОРТ ТОВАРОВ
        </Button>
      )}
    </>
  );
};

export default ButtonUpload;

