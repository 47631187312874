import React, { useEffect } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Button, Card, InputLabel, Tab, Tabs, TextField } from '@material-ui/core';
import { RouteComponentProps, useHistory } from 'react-router-dom';

import { IAppState } from '../../../../store/rootDuck';
import { setLayoutFooter, setLayoutSubheader } from '../../../../utils/layout';
import { actions as blogActions } from '../../../../store/ducks/blog.duck';
import Preloader from '../../../../components/ui/Preloader/Preloader';

import ButtonWithLoader from '../../../../components/ui/Buttons/ButtonWithLoader';
import homeStyles from '../../homeStyles';
import { a11yProps, TabPanel } from '../../../../components/other/Tab/TabPanel';
import RichEditor from '../../../../components/formComponents/RichEditor';
import { useEditorState, useFormatMessage, useShowErrors, useTabs } from '../../../../hooks';
import { API_DOMAIN } from '../../../../constants';
import FilesDropzone from '../../../../components/formComponents/FilesDropzone';

import { useUploadPublicationImage } from './hooks/uploadPublicationImage';
import { useCustomFormik } from './hooks/useCustomFormik';
import { useStyles } from './hooks/useStyles';

interface IField<T> {
  title: string;
  field: T;
}

const EditPublicationPage: React.FC<TPropsFromRedux & RouteComponentProps<{ id: string }>> = ({
  match: {
    params: { id },
  },
  loading,
  error,
  success,
  editPublication,
  clearEditPublication,

  publication,
  publicationLoading,
  publicationError,
  fetchPublication,
  clearFetchPublication,

  uploadLogoLoading,
  uploadLogoError,
}) => {
  const classes = useStyles();
  const homeClasses = homeStyles();
  const history = useHistory();
  const fm = useFormatMessage();
  const [tabValue, changeTab] = useTabs();
  const [editorState, setEditorState] = useEditorState(publication?.text || '');
  const { values, handleBlur, handleChange, errors, touched, handleSubmit } = useCustomFormik({
    id,
    data: publication,
    submit: editPublication,
    editorState,
  });
  const [uploadImageErr, uploadImage] = useUploadPublicationImage(publication?.id);

  setLayoutSubheader({ title: fm('edit_publication') });
  setLayoutFooter({ show: true });

  useEffect(() => {
    if (id) {
      fetchPublication({ id: +id });
    }
    return () => {
      clearFetchPublication();
      clearEditPublication();
    };
  }, [id]);

  useEffect(() => {
    if (publicationError) {
      history.push('/blog/all');
    }
  }, [publicationError]);

  useEffect(() => {
    if (success) history.goBack();
  }, [success, history]);

  useShowErrors([error, publicationError, uploadLogoError, uploadImageErr]);

  if (!publication || publicationLoading) return <Preloader />;

  const FIELDS: IField<keyof typeof values>[] = [
    { title: fm('title'), field: 'title' },
    { title: fm('description'), field: 'description' },
  ];

  return (
    <Card className={homeClasses.container}>
      <Tabs
        value={tabValue}
        onChange={changeTab}
        variant='scrollable'
        indicatorColor='primary'
        textColor='primary'
        aria-label='tabs'
      >
        <Tab label={fm('PUBLICATIONS.TABS.COMMON')} {...a11yProps(0)} />
        <Tab label={fm('PUBLICATIONS.TABS.PHOTOS')} {...a11yProps(1)} />
      </Tabs>

      <TabPanel value={tabValue} index={0} style={{ width: '100%' }}>
        <form
          onSubmit={handleSubmit}
          className={homeClasses.form}
          autoComplete='off'
          style={{ margin: 'auto' }}
        >
          {FIELDS.map(item => {
            return (
              <TextField
                key={item.field}
                type='text'
                label={item.title}
                margin='normal'
                name={item.field}
                value={values[item.field]}
                multiline={item.field === 'description'}
                rows={item.field === 'description' ? 3 : 0}
                variant='outlined'
                onBlur={handleBlur}
                onChange={handleChange}
                helperText={touched[item.field] && errors[item.field]}
                error={Boolean(touched[item.field] && errors[item.field])}
              />
            );
          })}

          <InputLabel shrink style={{ marginTop: 7 }}>
            {fm('text')}
          </InputLabel>

          <RichEditor
            editorState={editorState}
            setEditorState={setEditorState}
            placeholder=''
            style={{ minHeight: 305 }}
          />

          <div className={classes.actions}>
            <Button
              onClick={() => history.goBack()}
              className={classes.cancelBtn}
              variant='outlined'
              color='primary'
            >
              {fm('CATEGORY.BUTTON.CANCEL')}
            </Button>
            <ButtonWithLoader
              disabled={loading || uploadLogoLoading}
              loading={loading || uploadLogoLoading}
            >
              {fm('COMMON.BUTTON.SAVE')}
            </ButtonWithLoader>
          </div>
        </form>
      </TabPanel>

      <TabPanel value={tabValue} index={1}>
        <div
          style={{
            padding: 40,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Card style={{ marginBottom: 25 }}>
            <img
              src={`${API_DOMAIN}/${publication.avatar}`}
              alt=''
              style={{ maxWidth: 550, maxHeight: 500 }}
            />
          </Card>
          <FilesDropzone
            uploadFiles={uploadImage}
            avaliableNumberOfFilesToUpload={1}
            title={fm('COMMON.PHOTO.LOAD')}
            withCrop
          />
        </div>
      </TabPanel>
    </Card>
  );
};

const connector = connect(
  (state: IAppState) => ({
    loading: state.blog.editPublicationLoading,
    success: state.blog.editPublicationSuccess,
    error: state.blog.editPublicationError,

    publication: state.blog.publication,
    publicationLoading: state.blog.fetchPublicationLoading,
    publicationSuccess: state.blog.fetchPublicationSuccess,
    publicationError: state.blog.fetchPublicationError,

    uploadLogoLoading: state.blog.uploadPhotoLoading,
    uploadLogoSuccess: state.blog.uploadPhotoSuccess,
    uploadLogoError: state.blog.uploadPhotoError,
  }),
  {
    editPublication: blogActions.editPublicationRequest,
    clearEditPublication: blogActions.clearEditPublication,

    fetchPublication: blogActions.fetchEditPublicationRequest,
    clearFetchPublication: blogActions.clearFetchEditPublication,

    uploadLogo: blogActions.uploadPhotoRequest,
    clearUploadLogo: blogActions.clearUploadPhoto,
  }
);

type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(EditPublicationPage);
