import { Button } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useDefineUserRole } from '../../../hooks';
import { useDownloadFileCallback } from '../../../pages/home/products/hooks/useDownloadFileCallback';

const ButtonImportFile: React.FC = () => {
  const intl = useIntl();
  const { me } = useSelector(({ profile }: any) => profile);

  const isRoleAdmin = useDefineUserRole(me, 'ROLE_ADMIN');
  const isRoleVendor = useDefineUserRole(me, 'ROLE_VENDOR');
  const isRoleManager = useDefineUserRole(me, 'ROLE_MANAGER');

  const downloadExampleFile = useDownloadFileCallback();
  const goToImportFile = useCallback(() => {
    downloadExampleFile();
  }, []);

  return (
    <>
      {(isRoleAdmin || isRoleManager || isRoleVendor) && (
        <Button  color='default' variant='outlined' 
        style={{ marginLeft: '10px' }} onClick={goToImportFile}
        >
          ПРИМЕР ФАЙЛА
        </Button>
      )}
    </>
  );
};

export default ButtonImportFile;
