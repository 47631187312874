import { Button } from '@material-ui/core';
import React from 'react';
import { useSelector } from 'react-redux';
import { useDefineUserRole } from '../../../hooks';

interface IProps {
  onPress?: ()=> void
}

const ButtonExportFile: React.FC<IProps> = ({onPress}) => {
  const { me } = useSelector(({ profile }: any) => profile);

  const isRoleAdmin = useDefineUserRole(me, 'ROLE_ADMIN');
  const isRoleVendor = useDefineUserRole(me, 'ROLE_VENDOR');
  const isRoleManager = useDefineUserRole(me, 'ROLE_MANAGER');

  return (
    <>
      {(isRoleAdmin || isRoleManager || isRoleVendor) && (
        <Button  color='default' variant='outlined' 
        style={{ marginLeft: '10px' }} onClick={onPress}
        >
          ЭКСПОРТ ТОВАРОВ
        </Button>
      )}
    </>
  );
};

export default ButtonExportFile;

