import axios from 'axios';
import {
  STORES_URL,
  CREATE_STORE_URL,
  getStoreByIdUrl,
  setWorkingTimeUrl,
} from '../constants';
import { IEditWorkingTime, IStore } from '../interfaces/store';

export const createStore = (data: IStore) => axios.post(CREATE_STORE_URL, data);

export const getStores = (page: number, perPage: number, companyId?: number) =>
  axios.get(
    `${STORES_URL}${
      companyId ? `?company_id=${companyId}&` : '?'
    }page=${page}&per_page=${perPage}`
  );

export const editStore = (id: number, data: IStore) => axios.put(getStoreByIdUrl(id), data);

export const deleteStore = (id: number) => axios.delete(getStoreByIdUrl(id));

export const getStoreById = (id: number) => axios.get(getStoreByIdUrl(id));

export const setWorkingTime = (id: number, data: IEditWorkingTime) => {
  let url = setWorkingTimeUrl(id);
  const has = Object.prototype.hasOwnProperty;

  for (const prop in data) {
    if (has.call(data, prop)) {
      url += `${url === setWorkingTimeUrl(id) ? '?' : '&'}${prop}=${(data as any)[prop]}`;
    }
  }

  return axios.post(url, {});
};
