import React from 'react';
import { connect } from 'react-redux';
import objectPath from 'object-path';
import * as builder from '../../ducks/builder';
import TermDialog from '../../../app/components/other/Dialog/TermDialog';
import { LayoutContextConsumer } from '../LayoutContext';

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: false,
      dialogContentType: '',
    };
  }

  handleDialogOpen = contentType => {
    this.setState({
      dialogContentType: contentType,
      openDialog: true,
    });
  };

  handleDialogClose = () => {
    this.setState({
      openDialog: false,
    });
  };
  render() {
    // const today = new Date().getFullYear();
    const { openDialog, dialogContentType } = this.state;
    return (
      <LayoutContextConsumer>
        {({ footer: { show } }) =>
          show && (
            <div
              className={`kt-footer ${this.props.footerClasses} kt-grid__item kt-grid kt-grid--desktop kt-grid--ver-desktop`}
              id='kt_footer'
            >
              <div className={`kt-container ${this.props.footerContainerClasses}`}>
                <div className='kt-footer__copyright'>
                  <div>
                    Разработка:&nbsp;
                    <a
                      href='https://start-mobile.net'
                      target='_blank'
                      rel='noopener noreferrer'
                      className='kt-link'
                    >
                      Start Mobile
                    </a>
                    <br />
                    <a
                      rel='noopener noreferrer'
                      className='kt-link'
                      onClick={() => this.handleDialogOpen('user_agreement')}
                    >
                      Пользовательское соглашение
                    </a>
                    &nbsp;&nbsp;|&nbsp;&nbsp;
                    <a
                      onClick={() => this.handleDialogOpen('privacy_policy')}
                      rel='noopener noreferrer'
                      className='kt-link'
                    >
                      Политика конфиденциальности
                    </a>
                    {/*&nbsp;&nbsp;|&nbsp;&nbsp;*/}
                    {/*<a*/}
                    {/*  onClick={() => this.handleDialogOpen('shipping_policy')}*/}
                    {/*  rel='noopener noreferrer'*/}
                    {/*  className='kt-link'*/}
                    {/*>*/}
                    {/*  Доставка*/}
                    {/*</a>*/}
                    {/*&nbsp;&nbsp;|&nbsp;&nbsp;*/}
                    {/*<a*/}
                    {/*  onClick={() => this.handleDialogOpen('refund_policy')}*/}
                    {/*  rel='noopener noreferrer'*/}
                    {/*  className='kt-link'*/}
                    {/*>*/}
                    {/*  Возврат*/}
                    {/*</a>*/}
                  </div>
                  {/*<div className='kt-footer__menu'></div>*/}
                  <>
                    <TermDialog
                      isOpen={openDialog}
                      handleClose={this.handleDialogClose}
                      contentType={dialogContentType}
                    />
                  </>
                </div>
              </div>
            </div>
          )
        }
      </LayoutContextConsumer>
    );
  }
}

const mapStateToProps = store => ({
  fluid: objectPath.get(store.builder.layoutConfig, 'footer.self.width') === 'fluid',
  footerClasses: builder.selectors.getClasses(store, {
    path: 'footer',
    toString: true,
  }),
  footerContainerClasses: builder.selectors.getClasses(store, {
    path: 'footer_container',
    toString: true,
  }),
});

export default connect(mapStateToProps)(Footer);
