import React, { useEffect, useState } from 'react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Button } from '@material-ui/core';
import { useFormatMessage } from '../../../hooks';

interface IProps {
  src: string;
  onSubmit: (completedCrop: any) => void;
  aspect?: number;
}

const generateBlob = async (cropProp: any, image: any, setBlob: any) => {
  setBlob(null);
  const blob = await new Promise(resolve => {
    const canvas = document.createElement('canvas');
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = Math.ceil(cropProp.width * scaleX);
    canvas.height = Math.ceil(cropProp.height * scaleY);
    const ctx = canvas.getContext('2d');
    if (!ctx) return;
    // Clear the canvas and add a transparent border
    ctx.clearRect(0, 0, canvas.width, canvas.height);
    const newImage = new Image();
    newImage.crossOrigin = 'anonymous';
    newImage.onload = () => {
      // Draw the cropped image onto the canvas
      ctx.drawImage(
          newImage,
          cropProp.x * scaleX,
          cropProp.y * scaleY,
          cropProp.width * scaleX,
          cropProp.height * scaleY,
          0,
          0,
          canvas.width,
          canvas.height
      );
      canvas.toBlob(
          blob => {
            resolve(blob);
          },
          'image/jpeg',
          1
      );
    };
    newImage.src = image.src;
  });
  setBlob(blob);
};


export const CroppedImage: React.FC<IProps> = ({ src, onSubmit, aspect = 16 / 9 }) => {
  const [img, setImg] = useState<any>(null);
  const [crop, setCrop] = useState<any>({ aspect });
  const [blob, setBlob] = useState<string | null>(null);
  // eslint-disable-next-line no-nested-ternary
  const minSize = img ? (img.width > img.height ? img.height : img.width) : undefined;

  const fm = useFormatMessage();

  useEffect(() => {
    if (img) {
      setCrop({
        aspect,
        width: img.width,
        minWidth: minSize / 3,
        minHeight: minSize / 3,
      });
    }
  }, [src, img]);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minWidth: 300 }}>
      <ReactCrop
        src={src}
        onImageLoaded={img => setImg(img)}
        crop={crop}
        minWidth={minSize / 3}
        minHeight={minSize / 3}
        onChange={(c: any) => setCrop(c)}
        onComplete={(crop: any) => {
          if (blob) window.URL.revokeObjectURL(blob); // clear previous blob
          if (img) {
            generateBlob(crop, img, setBlob);
          }
        }}
      />

      <Button
        onClick={() => onSubmit(blob)}
        variant='contained'
        color='primary'
        disabled={!blob}
        style={{ marginTop: 15, color: 'white' }}
      >
        {fm('COMMON.BUTTON.APPLY')}
      </Button>
    </div>
  );
};
