/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react';
import { connect } from 'react-redux';
import objectPath from 'object-path';
import { withRouter } from 'react-router-dom';

import BreadCrumbs from './components/BreadCrumbs';
import { LayoutContextConsumer } from '../LayoutContext';
import * as builder from '../../ducks/builder';
import SearchField from '../../../app/components/ui/SearchField';
import ButtonAddProduct from '../../../app/components/ui/Buttons/ButtonAddProduct';
import { UserRoles } from '../../../app/interfaces/user';
import ButtonUpload from '../../../app/components/ui/Buttons/ButtonUpload';
import ButtonGetCategiry from '../../../app/components/ui/Buttons/ButtonGetCategoryId';
import ButtonImportFile from '../../../app/components/ui/Buttons/ButtonImportFile';
import ButtonExportFile from '../../../app/components/ui/Buttons/ButtonExportFile';

// import { QuickActions } from './components/QuickActions';
// import { ReactComponent as SortNum1Icon } from '../../../_metronic/layout/assets/layout-svg-icons/SortNum1.svg';

class SubHeader extends React.Component {
  render() {
    const {
      subheaderCssClasses,
      subheaderContainerCssClasses,
      subheaderMobileToggle,
      userRoles,
      me,
    } = this.props;
    return (
      <LayoutContextConsumer>
        {/*{({ subheader: { title, breadcrumb } }) => (*/}

        {({ subheader: { title, breadcrumb, description, show, setModal, setExportFile } }) =>
          show && (
            <div
              id='kt_subheader'
              className={`kt-subheader ${subheaderCssClasses} kt-grid__item`}
            >
              <div className={`kt-container ${subheaderContainerCssClasses}`}>
                <div className='kt-subheader__main'>
                  <>
                    {subheaderMobileToggle && (
                      <button
                        className='kt-subheader__mobile-toggle kt-subheader__mobile-toggle--left'
                        id='kt_subheader_mobile_toggle'
                      >
                        <span />
                      </button>
                    )}

                    <div className='kt-subheader__wrap-left'>
                      {breadcrumb && <BreadCrumbs items={breadcrumb} />}
                      {title && description && (
                        <span className='kt-subheader__separator kt-subheader__separator--v' />
                      )}
                      {title && (
                        <h3 className='kt-subheader__title' style={{ fontWeight: 600 }}>
                          {title}
                        </h3>
                      )}

                      {userRoles?.[0] !== 'ROLE_VENDOR' && (
                      <>
                       {this.props.location.pathname === '/products/catalog' && (
                        <ButtonAddProduct />
                      )}

                      {this.props.location.pathname === '/products/catalog' && (
                        <ButtonUpload onPress={setModal} />
                      )}
                      
                      {this.props.location.pathname === '/products/catalog' && (
                        <ButtonExportFile onPress={setExportFile} />
                      )}

                      {this.props.location.pathname === '/products/catalog' && (
                        <ButtonImportFile />
                      )}

                      {this.props.location.pathname === '/products/catalog' && (
                        <ButtonGetCategiry />
                      )}
                      </>
                    )}

                    </div>
                    {/* {!me || */}
                    {/*  (!me?.roles.includes(UserRoles.ROLE_BUYER) && */}
                    {/*    this.props.location.pathname === '/products/catalog' && ( */}
                    {/*      <SearchField isSubHeader={true} /> */}
                    {/*    ))} */}
                    {/* {userRoles?.[0] !== 'ROLE_VENDOR' && (
                    <Link to={`/cart`} className="kt-menu__link ">
                      <i className={`kt-menu__link-icon flaticon-cart`} />
                      <span
                        className="kt-menu__link-text"
                        style={{ paddingLeft: 10, fontWeight: 400 }}
                      >
                        <FormattedMessage
                          id="CART.SUBHEADER.TITLE"
                          defaultMessage={'CART.SUBHEADER.TITLE'}
                        />
                      </span>
                    </Link>
                  )} */}
                    {description && <span className='kt-subheader__desc'>{description}</span>}
                  </>
                </div>
              </div>
            </div>
          )
        }
      </LayoutContextConsumer>
    );
  }
}

const mapStateToProps = store => ({
  config: store.builder.layoutConfig,
  menuConfig: store.builder.menuConfig,
  userRoles: store.auth.user?.roles,
  subheaderMobileToggle: objectPath.get(store.builder.layoutConfig, 'subheader.mobile-toggle'),
  subheaderCssClasses: builder.selectors.getClasses(store, {
    path: 'subheader',
    toString: true,
  }),
  subheaderContainerCssClasses: builder.selectors.getClasses(store, {
    path: 'subheader_container',
    toString: true,
  }),
  me: store.profile.me,
});

export default withRouter(connect(mapStateToProps)(SubHeader));
