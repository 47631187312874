import { TRole } from '../pages/auth/interfaces';
import { menuConfigItems } from '../constants/menuConfigItems';

const {
  profile,
  vendorProducts,
  products,
  orders,
  users,
  legal,
  settings,
  catalog,
  // blog,
 // guestBlog,
  tariffs,
  // companies,
  // vendorUsers,
  vendorCompany,
  catalogForUser,
  chats,
  // tenders,
  buyersTenders,
  vendorsTenders,
  // addTender,
  allTenders,
  buyersDocuments,
  buyersOrders,
  usersManager,
} = menuConfigItems;

const getDefaultMenu = () => ({
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    items: [ catalogForUser, profile], // vendorsTenders,
  },
});

const getManagerMenu = () => ({
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    items: [catalogForUser, profile, usersManager, settings],
  },
});

const getAdminMenu = () => ({
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    items: [  products, users, settings, legal, orders], // allTenders, tariffs, chats
  },
});

const getVendorMenu = () => ({
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    items: [vendorProducts, vendorCompany, buyersOrders], // vendorsTenders chats, buyersDocuments,
  },
});

const getBuyerMenu = () => ({
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    items: [ catalogForUser], // buyersOrders, guestBlog ,buyersTenders,
  },
});

const getGuestMenu = () => ({
  header: {
    self: {},
    items: [],
  },
  aside: {
    self: {},
    items: [catalog], // guestBlog
  },
});

const getMenuConfig = (userRoles: TRole[] = ['ROLE_BUYER'], isAuthorized?: boolean) => {
  if (isAuthorized) {
    if (userRoles === undefined) {
      return getDefaultMenu();
    }
    if (userRoles[0] === 'ROLE_ADMIN') {
      return getAdminMenu();
    }
    if (userRoles[0] === 'ROLE_MANAGER') {
      return getManagerMenu();
    }
    if (userRoles[0] === 'ROLE_VENDOR') {
      return getVendorMenu();
    }
    if (userRoles[0] === 'ROLE_BUYER') {
      return getBuyerMenu();
    }
  } else {
    return getGuestMenu();
  }
};

export default getMenuConfig;
