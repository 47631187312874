import { useCallback } from 'react';
import fileSaver from 'file-saver';
import axios from 'axios';
import { useSnackbar } from 'notistack';

export const useDownloadFileCallback = () => {
  const { enqueueSnackbar } = useSnackbar();
  return useCallback(() => {
    const url = `/api/shop/yml/example`;
    axios
      .get(url)
      .then(res => {
        const blob = new Blob([res.data], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        fileSaver.saveAs(blob, `example.xml`);
      })
      .catch(err => {
        const text = err?.response?.data?.message || String(err);
        enqueueSnackbar(text, { variant: 'error' });
      });
  }, []);
};
