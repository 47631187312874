import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  Tab,
  Tabs,
  TextField,
} from '@material-ui/core';
import { useIntl } from 'react-intl';
import InputMask from 'react-input-mask';

import { a11yProps, TabPanel } from '../../../components/other/Tab/TabPanel';
import { ButtonWithLoader } from '../../../components/ui/Buttons';
import { useFormikRegistration } from '../hooks/useFormikRegistration';
import SelectField from '../../../components/ui/SelectField';
import { useHandleCountrySelect } from '../hooks/useHandleCountrySelect';
import { useShowErrors } from '../../../hooks/useShowErrors';
import { TAuthTabs } from '.';
import RegistrationTermsDialog from '../../../components/ui/Dialog/RegistrationTermsDialog';
import { useAxiosRegistrations } from '../hooks/useAxiosRegistration';

interface IProps {
  changeAuthTab: (newTab: TAuthTabs, props?: any) => void;
  initTab: number;
  countries: any;
  initCountryId?: number;
  initEmail?: string;
  initPhone?: string;
}

export const Registration: React.FC<IProps> = ({
  changeAuthTab,
  initTab,
  countries,
  initCountryId,
  initEmail,
  initPhone,
}) => {
  const intl = useIntl();
  const [tab, setTab] = useState(initTab);
  const [isTermsOpen, setIsTermsOpen] = useState(false);

  const { countryId, setCountryId, countryData } = useHandleCountrySelect(
    countries,
    initCountryId
  );

  const [data, loading, err, makeRequest, requestedType] = useAxiosRegistrations();

  const formik = useFormikRegistration(tab, initPhone, initEmail, countryData, makeRequest);

  // handle success
  useEffect(() => {
    if (data) {
      if (requestedType === 'phone') {
        changeAuthTab('EnterConfirmCode', { countryData, phone: formik.values.phone });
      } else {
        changeAuthTab('EmailSent', { email: formik.values.email });
      }
    }
  }, [data, requestedType, countryData, formik.values.phone, formik.values.email]);

  useShowErrors([err]);

  return (
    <>
      <div className='kt-login__body'>
        <div className='kt-login__form'>
          <div className='kt-login__title'>
            <h3>{intl.formatMessage({ id: 'AUTH.REGISTER.TITLE' })}</h3>
          </div>

          <form
            noValidate
            autoComplete='off'
            className='kt-form'
            onSubmit={formik.handleSubmit}
          >
            <Tabs
              value={tab}
              onChange={(_, newTab) => setTab(newTab)}
              variant='scrollable'
              indicatorColor='primary'
              textColor='primary'
              aria-label='tabs'
            >
              {/* <Tab label={intl.formatMessage({ id: 'BY_EMAIL' })} {...a11yProps(0)} /> */}
              {/* <Tab */}
              {/*  label={intl.formatMessage({ id: 'AUTH.MAIN.TAB.BY_PHONE' })} */}
              {/*  {...a11yProps(1)} */}
              {/* /> */}
            </Tabs>

            {/* select role */}
            {/* <RadioGroup */}
            {/*  name='role' */}
            {/*  value={formik.values.role} */}
            {/*  onChange={formik.handleChange} */}
            {/*  style={{ marginTop: 40, marginBottom: -25 }} */}
            {/* > */}
            {/*  <FormLabel component='legend'> */}
            {/*    {intl.formatMessage({ id: 'AUTH.REGISTER.ROLE_TITLE' })} */}
            {/*  </FormLabel> */}
            {/*  <FormControlLabel */}
            {/*    value='ROLE_VENDOR' */}
            {/*    control={<Radio />} */}
            {/*    label={intl.formatMessage({ id: 'USER.ROLES.VENDOR' })} */}
            {/*  /> */}
            {/*  <FormControlLabel */}
            {/*    value='ROLE_BUYER' */}
            {/*    control={<Radio />} */}
            {/*    label={intl.formatMessage({ id: 'USER.ROLES.BUYER' })} */}
            {/*  /> */}
            {/* </RadioGroup> */}

            {/* email */}
            <TabPanel value={tab} index={0}>
              <div className='form-group'>
                <TextField
                  type='text'
                  label={intl.formatMessage({ id: 'AUTH.INPUT.EMAIL' })}
                  margin='normal'
                  className='kt-width-full'
                  name='email'
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  value={formik.values.email}
                  helperText={formik.touched.email && formik.errors.email}
                  error={Boolean(formik.touched.email && formik.errors.email)}
                />
              </div>
            </TabPanel>

            {/* country and phone */}
            <TabPanel value={tab} index={1}>
              <div className='form-group'>
                <SelectField
                  label={intl.formatMessage({ id: 'AUTH.INPUT.COUNTRIES' })}
                  data={countries}
                  selectedValue={countryId}
                  setSelectedValue={setCountryId}
                  variant='standard'
                  renderItemText={item => (intl.locale === 'ru' ? item.ru_name : item.en_name)}
                />
                {countryData && (
                  <InputMask
                    mask={countryData.mask}
                    onBlur={formik.handleBlur}
                    value={formik.values.phone}
                    alwaysShowMask
                    onChange={(e: any) => {
                      formik.setFieldValue(
                        'phone',
                        e.target.value ? e.target.value.replace(/[^0-9]/g, '') : ''
                      );
                    }}
                  >
                    {(inputProps: any) => (
                      <TextField
                        {...inputProps}
                        type='tel'
                        label={intl.formatMessage({ id: 'AUTH.INPUT.PHONE' })}
                        margin='normal'
                        className='kt-width-full'
                        name='phone'
                        helperText={formik.touched.phone && formik.errors.phone}
                        error={Boolean(formik.touched.phone && formik.errors.phone)}
                        onBlur={formik.handleBlur}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position='start'>
                              {' '}
                              +{countryData.code}{' '}
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  </InputMask>
                )}
              </div>
            </TabPanel>

            {/* fio */}
            {/* <TextField
              type='text'
              label={intl.formatMessage({ id: 'USER.EDIT.FIO' })}
              margin='normal'
              name='fio'
              value={formik.values.fio}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              helperText={(formik.touched.fio && formik.errors.fio) || fm('COMMON.OPTIONAL')}
              error={Boolean(formik.touched.fio && formik.errors.fio)}
            /> */}

            {/* accept terms */}
            <div className='form-group mb-0' style={{ fontWeight: 400, marginTop: 15 }}>
              <Checkbox
                color='primary'
                name='acceptTerms'
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                checked={formik.values.acceptTerms}
                style={{ marginLeft: -10 }}
              />
              {intl.formatMessage({ id: 'AUTH.REGISTER.AGREE_TERM' })}{' '}
              <div
                className='kt-link'
                style={{ cursor: 'pointer' }}
                onClick={() => setIsTermsOpen(true)}
              >
                {intl.formatMessage({ id: 'AUTH.GENERAL.LEGAL' })}
              </div>
            </div>

            {/* buttons */}
            <div
              style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}
            >
              <div className='kt-login__actions' style={{ marginRight: 30 }}>
                <button
                  onClick={() => changeAuthTab('CheckInSystem')}
                  type='button'
                  className='btn btn-secondary btn-elevate kt-login__btn-secondary'
                >
                  {intl.formatMessage({ id: 'AUTH.GENERAL.BACK_BUTTON' })}
                </button>
              </div>

              <div className='kt-login__actions'>
                <ButtonWithLoader
                  onPress={formik.handleSubmit}
                  disabled={!formik.values.acceptTerms}
                  loading={loading}
                >
                  {intl.formatMessage({ id: 'AUTH.GENERAL.SIGNUP_BUTTON' })}
                </ButtonWithLoader>
              </div>
            </div>
          </form>
        </div>
      </div>
      <RegistrationTermsDialog
        isOpen={isTermsOpen}
        handleClose={() => setIsTermsOpen(false)}
      />
    </>
  );
};
