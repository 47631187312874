import React from 'react';
import { createStyles, makeStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useIntl } from 'react-intl';

import UserProfile from '../../partials/layout/UserProfile';
import SearchField from '../../../app/components/ui/SearchField';
import { UserRoles } from '../../../app/interfaces/user';

const useStyles = makeStyles(theme =>
  createStyles({
    menuContainer: {
      display: 'block',
      backgroundColor: 'red',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    menu: {
      width: 25,
      height: 20,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    topbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: 'calc(100%)',
      gap: 100,
    },
    toggler: {
      height: 3,
      borderRadius: 3,
      width: '100%',
      backgroundColor: '#cecece',
    },
    title: {
      margin: 0,
      fontWeight: 'bold',
      fontSize: 15,
      padding: '5px 10px',
      backgroundColor: '#eaeaea',
      borderRadius: 5,
    },
  })
);

const Topbar = props => {
  const classes = useStyles();
  const intl = useIntl();
  return (
    <div className={`kt-header__topbar ${classes.topbar}`}>
      <div className={classes.menuContainer}>
        {props.showBurger ? (
          <div id='kt_header_mobile_toggler' className={classes.menu}>
            <span className={classes.toggler} />
            <span className={classes.toggler} />
            <span className={classes.toggler} />
          </div>
        ) : (
          <Link to={'/products/catalog'}>
            <p className={classes.title}>
              {intl.formatMessage({ id: 'MENU.PRODUCTS.CATALOG' })}
            </p>
          </Link>
        )}
      </div>
      <div />
      {props?.me?.roles.includes(UserRoles.ROLE_BUYER) ? (
        <>
          <SearchField isSubHeader={true} />
        </>
      ) : !props.isAuthorized ? (
        <>
          <SearchField isSubHeader={true} />
        </>
      ) : (
        <></>
      )}
      <UserProfile showAvatar={true} showHi={true} showBadge={false} />
    </div>
  );
};

export default Topbar;
