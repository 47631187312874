/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/pages/auth/AuthPage`, `src/pages/home/HomePage`).
 */

import React, { useEffect } from 'react';
import {Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { useLastLocation } from 'react-router-last-location';
import { makeStyles, createStyles } from '@material-ui/core';
import { SnackbarProvider } from 'notistack';

import HomePage from '../pages/home/HomePage';
import ErrorsPage from '../pages/home/error/ErrorsPage';
import LogoutPage from '../pages/auth/Logout';
import { AuthPage } from '../pages/auth/AuthPage';
import { LayoutContextProvider } from '../../_metronic';
import Layout from '../../_metronic/layout/Layout';
import * as routerHelpers from './RouterHelpers';
import { IAppState } from '../store/rootDuck';

const useStyles = makeStyles(theme =>
  createStyles({
    success: {
      backgroundColor: '#0abb87 !important',
    },
    error: {
      backgroundColor: theme.palette.error.main,
    },
  })
);

export const Routes = withRouter(({ history }) => {
  const classes = useStyles();
  const lastLocation = useLastLocation();

  routerHelpers.saveLastLocation(lastLocation);

  const { isAuthorized, configMenu, userLastLocation } = useSelector(
    ({ auth, builder: { menuConfig } }: IAppState) => ({
      configMenu: menuConfig,
      isAuthorized: auth.user != null,
      userLastLocation: routerHelpers.getLastLocation(),
    }),
    shallowEqual
  );

  // Delete last location after logout
  useEffect(() => {
    if (!isAuthorized) {
      routerHelpers.forgotLastLocation();
    }
  }, [isAuthorized]);

  return (
    <SnackbarProvider
      maxSnack={3}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      classes={{
        variantSuccess: classes.success,
        variantError: classes.error,
      }}
    >
      {/* Create `LayoutContext` from current `history` and `menuConfig`. */}
      <LayoutContextProvider history={history} menuConfig={configMenu}>
        <Switch>
          <Route path='/auth' component={AuthPage} />
          <Route path='/error' component={ErrorsPage} />
          <Route path='/logout' component={LogoutPage} />
          <Route path='/'>
              {!isAuthorized ? (
                  /* Redirect to `/auth` when user is not authorized */
                  <Redirect to='/auth/main' />
              ) : (
                  <Layout>
                      <HomePage userLastLocation={userLastLocation} />
                  </Layout>
              )}
          </Route>
        </Switch>
      </LayoutContextProvider>
    </SnackbarProvider>
  );
});
