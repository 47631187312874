import React, { FC, useCallback, useState } from 'react';
import {
  Button,
  CardActions,
  Card,
  CardContent,
  CardMedia,
  createStyles,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import TablePaginator from '../../../../components/TablePaginator/TablePaginator';
import { formatAsThousands } from '../../../../utils/utils';
import { toAbsoluteUrl } from '../../../../../_metronic';
import { IProduct } from '../../../../interfaces/product';
import { useDefineUserRole, useFormatMessage } from '../../../../hooks';
import { IUser, UserRoles } from '../../../../interfaces/user';
import { getProductImage } from '../utils/getProductImage';
import { useCreateChat } from '../../chats/chatsPage/hooks/useCreateChat';

const useStyles = makeStyles(theme =>
  createStyles({
    // container: {
    //   marginBottom: theme.spacing(3),
    //   display: 'flex',
    //   flexWrap: 'wrap',
    //   justifyContent: 'flex-start',
    //   marginTop: -15,
    // },
    // productCard: {
    //   width: '30%',
    //   minWidth: 250,
    //   maxWidth: 300,
    //   margin: 15,
    //   cursor: 'pointer',
    //   transition: '.2s ease-out',
    //   boxShadow: 'none !important',
    //   '&:hover': {
    //     transform: 'scale(1.03)',
    //   },
    // },
    container: {
      display: 'grid',
      gridGap: 24,
      gridTemplateColumns: 'repeat(4, 220px)',

      [theme.breakpoints.down('sm')]: {
        gridTemplateColumns: 'repeat(2, 1fr)',
      },
      [theme.breakpoints.up('sm')]: {
        gridTemplateColumns: 'repeat(2, 1fr)',
      },
      [theme.breakpoints.up('md')]: {
        gridTemplateColumns: 'repeat(3, 1fr)',
      },
      [theme.breakpoints.up('lg')]: {
        gridTemplateColumns: 'repeat(3, 1fr)',
      },
      [theme.breakpoints.up('xl')]: {
        gridTemplateColumns: 'repeat(4, 1fr)',
      },
      // @ts-ignore
      [theme.breakpoints.up('xxl')]: {
        gridTemplateColumns: 'repeat(5, 1fr)',
      },
    },
    productCard: {
      minWidth: '100%',
      marginBottom: 25,
      cursor: 'pointer',
      transition: '.3s ease-out',
      boxShadow: 'none !important',

      '&:hover': {
        transform: 'scale(1.03)',
      },

      [theme.breakpoints.up('md')]: {
        width: 220,
      },
    },
  })
);

interface IProductsProps {
  data: IProduct[];

  page?: number;
  perPage?: number;
  total?: number;
  categoryId: string;
  companyId: string;
  fetch?: (data: { page: number; perPage: number }) => void;
  viewAction: any;
  editAction?: any;
  handleDeleteDialog?: any;
  isAuthorized: boolean;
  isAdmin: boolean;
  me: IUser | undefined;
  addProductLoading: boolean;
  handleCartDialog: any;
  isPaginator?: boolean;
}

const ProductsGrid: FC<IProductsProps> = ({
  data,
  categoryId,
  page,
  perPage,
  total,
  fetch,
  companyId,
  viewAction,
  editAction,
  handleDeleteDialog,
  isAdmin,
  me,
  addProductLoading,
  handleCartDialog,
  isPaginator = true,
}) => {
  const fm = useFormatMessage();
  const classes = useStyles();
  const [heightImg, setHeightImg] = useState(294);
  const history = useHistory();
  const isBuyer = useDefineUserRole(me, 'ROLE_BUYER');
  const [createChatFetch, loadingCreated, createChat] = useCreateChat();

  const getStock = useCallback(
    (product: IProduct) => {
      return isBuyer || !me ? product.stock_info?.available_quantity : product.stock_quantity;
    },
    [isBuyer, me]
  );

  const newChat = useCallback((userId?: string | number) => {
    createChatFetch({ userId });
  }, []);

  return (
    <>
      <div className={classes.container}>
        {data.map(item => {
          return (
            <Card key={item.id} className={classes.productCard}>
              <CardMedia
                style={{
                  height: heightImg,
                  minHeight: 294,
                  objectFit: 'cover',
                  // opacity: !getStock(item) || (getStock(item) || 0) < 0 ? 0.1 : 1,
                }}
                title={item.name}
                onClick={() => viewAction(item)}
                src={getProductImage(item)}
                component='img'
                onError={(e: any) => {
                  e.target.src = toAbsoluteUrl('/images/placeholder.png');
                }}
              />
              <CardContent onClick={() => viewAction(item)}>
                <Typography gutterBottom variant='h5' component='h2' style={{ lineHeight: 1 }}>
                  <b>{formatAsThousands(item.price)} ₽</b>
                </Typography>
                <Typography
                  color='inherit'
                  component='p'
                  style={{ marginBottom: 5, lineHeight: 1.3 }}
                >
                  {item.name}
                </Typography>
                {/* <Typography color='textSecondary' component='p'>
                  {item.modification.gearbox_type.name}
                </Typography> */}
              </CardContent>
              <CardActions style={{ padding: '0px 7px 7px 7px', justifyContent: 'center' }}>
              {!me?.roles.includes(UserRoles.ROLE_VENDOR) && !isAdmin && (
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        height: 45,
                      }}
                    >
                      <Button
                        // disabled={
                        //   addProductLoading || !getStock(item) || (getStock(item) || 0) < 0
                        // }
                        onClick={() => {
                          handleCartDialog(item);
                        }}
                        color='primary'
                      >
                        {/* {!getStock(item) || (getStock(item) || 0) < 0 */}
                        {/*  ? fm('PRODUCT.BUTTON.SOLD.OUT') */}
                        {/*  : fm('PRODUCT.BUTTON.ADD_CART')} */}
                        {fm('PRODUCT.BUTTON.ADD_CART')}
                      </Button>
                    </div>
                  )}
                {isAdmin && (
                  <>
                    {editAction && (
                      <Button onClick={() => editAction(item)} color='primary'>
                        {fm('edit')}
                      </Button>
                    )}

                    {handleDeleteDialog && (
                      <Button
                        onClick={() => handleDeleteDialog(item)}
                        color='secondary'
                        style={{ marginLeft: 8 }}
                      >
                        {fm('COMMON.BUTTON.DELETE')}
                      </Button>
                    )}
                  </>
                )}
                {/* {(me?.roles.includes(UserRoles.ROLE_VENDOR) ||
                  me?.roles.includes(UserRoles.ROLE_BUYER)) && (
                  <div
                    style={{
                      width: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      height: 45,
                    }}
                  >
                    <Button
                      style={{ width: '90%' }}
                      color='primary'
                      variant='outlined'
                      onClick={() => {
                        // newChat(me?.id);
                        history.push({
                          pathname: `/chats`,
                          state: {
                            createUserChat: me?.id,
                          },
                        });
                      }}
                    >
                      {fm('Поддержка')}
                    </Button>
                  </div>
                )} */}
              </CardActions>
            </Card>
          );
        })}
      </div>
      {isPaginator && fetch && (
        <div style={{ display: 'flex', justifyContent: 'center' }} id='catalog-paginator'>
          <TablePaginator
            id={+categoryId}
            page={page || 1}
            perPage={perPage || 20}
            total={total || 0}
            realPerPage={data.length}
            fetchRows={fetch}
            companyId={companyId}
            rowsPerPageOptions={[12, 36, 108]}
            label={fm('PRODUCT.TABLE.PERPAGE')}
          />
        </div>
      )}
    </>
  );
};

export default ProductsGrid;
